#logo_nosotros{
    width: auto;
    max-width: 90%;
}
#lema{
    color: black;
}
#nombre{
    margin-bottom: 2rem;
}
.img-container{
    margin: 0 !important;
    max-height: 350px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100% !important;
}
#mision-vision div{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 1rem;
    width: 50%;
}

@media(max-width:1199.99px){
    #mision-vision div{
        margin-left: 0;
        width: 100%;
    }
}