:root{
    --principal: #2E3092;
    --principal-hover: #19194d;
    --red: #FE2A2A;
    --red-hover:rgb(165, 33, 33);
    --green: #53E111;
    --green-hover: #3d9e0d;
}
@font-face {
    font-family: 'Open Sans';
    src: url('/public/fonts/OpenSans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('/public/fonts/OpenSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}
body{
    background-color: #F5F5F5;
}
*{
    box-sizing: border-box;
    margin: 0;
}
h1{
    margin: 0;
    font-size: 32px;
    text-align: center;
}
h2{
    font-size: 28px;
    margin-bottom: 0.5rem;
    color: var(--principal);
    text-align: center;
}
h3{
    font-size: 23px;
    text-align: center;
}
h4{
    font-size: 18px;
    text-align: center;
}
main{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: center;
    justify-content: space-around;
    min-height: 80vh;
}
p{
    margin:0;
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 1.57143;
    text-align: justify;
}
section{
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    max-width: 960px;
    width: 95%;
}
img{
    width:100%;
    height: auto;
}
article{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5rem;
}
button{
    margin: 0;
    padding: 0;
    background-color: transparent;
    text-decoration: none;
    box-sizing: border-box;
    border: none;
}
button:hover{
    background-color: transparent;
}
footer{
    display: flex;
    width: 100vw;
    height: auto;
    background-color: var(--principal);
    padding: 0.5rem;
}
footer section{
    align-items: center;
}
.img_container{
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
}
#f_creative_commons{
    display: flex;
    justify-content: flex-end;
}
@media (max-width: 1199.98px) {
    section{
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    footer{
        flex-direction: column;
        align-items: center;
    }
    #f_creative_commons{
        justify-content: space-around;
    }
}