:root{
    --type-0:var(--principal);
    --type-1:#7030A0;
    --type-2:#BDD7EE;
    --type-3:linear-gradient(white, #FF98FF);
    --type-4:#00B050;
    --type-5:#F3F021;
    --type-6:#FF0000;
    --type-7:radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(59,255,177,1) 100%);
    --type-8:linear-gradient(#E69307, #D06B0D); 
    --type-9:#9FDF11;
    --type-10:linear-gradient(to right bottom, #ffffff, #ffff20);
    --type-11:#F8CBAD;
    --type-12:#5B9BD5;
    --type-13:#3B6149;
    --type-14:#6A4808;
}

#calendar{
    padding:0;
}

.selected-date, .react-calendar__tile--active.react-calendar__tile--now{
    background-color: var(--principal) !important; /* Cambia el color de fondo al azul (puedes personalizarlo) */
    color: white; /* Cambia el color del texto a blanco */
    border: 2px solid var(--principal) !important; /* Cambia el borde al mismo color de fondo o personalízalo */
}

.react-calendar {
    font-family: "Roboto Slab","Times New Roman",Times,serif;
    width:100%;
}
.react-calendar__month-view__weekdays__weekday abbr{
    text-decoration: none !important;
}

#special-date{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin-top: 1rem;
    box-shadow: 0px 0px 13px -3px rgba(0,0,0,0.5);
    background-color: white;
    margin-bottom: 1rem;
}
.nameEvent{
    font-weight: bold;
    font-size: 20px;
    text-align: center;
}
#special-date p, input{
    text-align: center;
}
#special-date .inputCalendar{
    width: 100%;
    margin-bottom: 0.5rem;
    border: 1px solid black;
}
.special-date{
    color: white;
    font-weight: bold;
}
.type-0{
    color:white;
    background: var(--principal);
}
.type-0:hover{
    background: var(--principal-hover);
}
.type-1{
    color:white;
    background: var(--type-1);
}
.type-2{
    color:black;
    background: var(--type-2);
}
.type-3{
    color:black;
    background: var(--type-3);
}
.type-4{
    color:black;
    background: var(--type-4);
}
.type-5{
    color:black;
    background: var(--type-5);
}
.type-6{
    color:white;
    background: var(--type-6);
}
.type-7{
    color:black;
    background: var(--type-7);
}
.type-8{
    color:white;
    background: var(--type-8);
}
.type-9{
    color:black;
    background: var(--type-9);
}
.type-10{
    color:black;
    background: var(--type-10);
}
.type-11{
    color:black;
    background: var(--type-11);
}
.type-12{
    color:black;
    background: var(--type-12);
}
.type-13{
    color:white;
    background: var(--type-13);
}
.type-14{
    color:white;
    background: var(--type-14);
}
.type-1:hover{
    background: var(--type-1) !important;
}
.type-2:hover{
    background: var(--type-2) !important;
}
.type-3:hover{
    background: var(--type-3) !important;
}
.type-4:hover{
    background: var(--type-4) !important;
}
.type-5:hover{
    background: var(--type-5) !important;
}
.type-6:hover{
    background: var(--type-6) !important;
}
.type-7:hover{
    background: var(--type-7) !important;
}
.type-8:hover{
    background: var(--type-8) !important;
}
.type-9:hover{
    background: var(--type-9) !important;
}
.type-10:hover{
    background: var(--type-10) !important;
}
.type-11:hover{
    background: var(--type-11) !important;
}
.type-12:hover{
    background: var(--type-12) !important;
}
.type-13:hover{
    background: var(--type-13) !important;
}
.type-14:hover{
    background: var(--type-14) !important;
}
.color{
    width: 30px;
    height: 20px;
    border: 1px solid black;
}
#colors p{
    text-align: center;
}
#colors{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    background-color: white;
    border: 1px solid #a0a096;
    margin-bottom: 1rem;
}
.colors-separator{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;
    margin-top: 0.3rem;
    flex-wrap: wrap;
}
.separator{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:center;
    margin: 0.2rem;
    max-width: 45%;
    white-space: wrap;
}
@media(max-width:1199.99px){
    .colors-separator{
        width: auto;
        padding: 0.3rem;
        
    }
    #special-date{
        width: 100%;
    }
}