@font-face {
    font-family: 'Roboto Slab';
    src: url('/public/fonts/RobotoSlab.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

:root{
    --fondo: #2E3092;
}

*{
    box-sizing: border-box;
    font-size: 20px;
}

.header div{
    position: relative;
}

body{
    font-family: "Open Sans",Helvetica,Arial,sans-serif;
    overflow-x:hidden;
    max-width: 100%;
}

.header{
    padding: 0.3rem;
    background-color: var(--fondo);
    display: flex;
    position:relative;
    z-index: 8;
}


a{
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6{
    font-family: "Roboto Slab","Times New Roman",Times,serif;
    font-weight: 700;
    font-weight: bold;
}

h3{
    margin: 0.2rem;
    color: white;
    padding: 0.5rem;
}
#logo{
    filter: invert();
    width: 90%;
}

#logo_container{
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: auto;
    width:20%;
}

#parts{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    width:80%;
    z-index: 1;
}
.social{
    display: flex;
    align-items: center;
    color: white;
    margin: 0.2rem;
    height: 3vh;
}

.social:hover{
    text-shadow: 0px 0px 9px white;
}

.contact_information{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: auto;
}

.contact_information a{
    padding: 0.5rem;
}

.icon_social_header{
    filter:invert(1);
    width: auto;
    height: 20px;
    margin-right: 0.5rem;
}

#btn_toggle{
    background: none;
    border:none;
    box-sizing: border-box;
    font-family: "Roboto Slab","Times New Roman",Times,serif;
    border-bottom: 1px solid white;
}

#down a, #down li, #down p, #btn_toggle{
    width: auto;
    color: white;
    margin-left: 0.3rem !important;
    box-sizing: border-box;
    font-family: "Roboto Slab","Times New Roman",Times,serif;
    text-align: left;
    margin: 0.2rem;
    text-align: center;
}

#up{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

#down{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    color:white;
    position: sticky;
    transition: all ease-in-out;
    width: 100%;
}

#opc_start{
    border-left: 0px;
}

#down a:hover{
    color: white;
    text-shadow: 0px 0px 9px white;
}

#btn_toggle{
    display: none;
}

#img_btn{
    filter: invert(1);
    width: 20px;
    height: auto;
    margin:0.3rem;
    transition: transform 0.3s ease-in-out;
}

#logo_container_fixed{
    max-width: 100px;
}
.img_adorno{
    width: 40%;
    height: auto;
    transform: rotate(0deg);
    position: absolute;
    top: -2rem;
    right: -5rem;
    z-index: 0;
}

ul.menu{
    list-style-type: none;
    margin: 0;
    padding: 0;
}

/* Estilo de los elementos del menú */
.menu > li {
    position: relative; /* Contenedor posicionado para el submenú absoluto */
    cursor: pointer;
    white-space: nowrap;
    display: flex;
  }

   /* Estilo del submenú */
   .submenu {
        display: none;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: absolute; /* Posicionamiento absoluto */
        top: 100%; /* Abajo del elemento del menú */
        left: 0;
        background-color: var(--principal);
        min-width: 100%; /* Mínimo ancho del elemento padre */
        z-index: 1000; /* Asegura que el submenú esté encima de otros componentes */
        padding: 0;
        margin-top: 0.5rem;
        box-shadow: 0px 0px 13px -3px rgba(0,0,0,0.5);
  }

  /* Estilo de los elementos del submenú */
  .submenu li {
    padding: 8px 12px;
    text-decoration: none;
    display: block;
    text-shadow: none;
    z-index: 100000;
  }

  .submenu li:hover{
    text-shadow: 0px 0px 9px white;
  }

  /* Muestra el submenú cuando se hace hover en el elemento del menú */
  .menu > li.open > .submenu {
    display: flex;

  }

  .header-fixed{
    z-index: 7 !important;
  }
/*Responsive*/
@media (min-width:2500px) {
    *{
        font-size: 2rem;
    }
    .icon_social_header{
        height: 2rem;
    }
}
@media (max-width: 1199.98px) {
    .img_adorno{
        display: none;
    }
    #logo_container{
        width:80%;
    }
    .opc{
        border:none;
    }
    .header{
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    .header #up{
        margin-bottom: 0.5rem;
    }
    #parts{
        align-items:center ;
    }
    .title_c_information{
        display: none;
    }
    #down{
        display: flex;
        flex-direction: column;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
    }
    #btn_toggle{
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid white;
        width: 50%;
    }
    #down a{
        width: 100%;
        text-align: center;
    }
    #down.open {
        
        max-height: 1000px;
        transition: max-height 0.3s ease-in-out;
    }
    #down.open .opc{
        border:none;
        flex-direction: column;
        border: none;
    }
    .img_op{
        transform: rotate(180deg);
        transition: transform 0.3s ease-in-out;
    }
    .img_nop{
        transform: rotate(360deg);
        transition: transform 0.3s ease-in-out;
    }
    .contact_information{
        flex-direction: column;
        align-items: center;
    }
}