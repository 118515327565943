.subject{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    margin-left: 1rem;
    box-shadow: 0px 0px 13px -3px rgba(0,0,0,0.5);
    width: 40%;
}
.subject-interaction{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.subject-interaction h3{
    margin: 0;
    padding: 0;
    margin-right: 1rem;
}
.subject-interaction img{
    width: 20px;
    filter: invert(1);
    transform: rotate(-45deg);
    transition: transform 0.1s ease-in-out;
}
.subject-interaction.open-button img{
    transform: rotate(0deg);
}
.subject-list{
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.1s ease;
    background-color: white;
}
.subject-list.open{
    max-height: 1000px;
    transition: max-height 0.3s ease;
}
.subject-list ul{
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    padding: 0.5rem;
}
.subject-list ul li h4{
    padding: 0;
    margin: 0;
    margin-bottom: 0.2rem;
    text-align: left;
}
@media(max-width: 1199.98px){
    .subject{
        width: 100%;
    }
}