*{
    box-sizing: border-box;
}
form > img{
    filter:none !important;
    max-width: 500px;
}
form > h1, h4{
    color: black;
    text-align: center;
    margin-top: 0.3rem;
}
form h1{
    font-size: 35px;
}
#buzon{
    flex-direction: column;
    align-items: center;
}
input[type="text"]{
    border: 1px solid black !important;
}
textarea{
    width: 100%;
    resize: vertical;
    border: 1px solid black;
    margin-bottom: 0.7rem;
}
#btn-enviar{
    background-color: var(--principal);
    color: white;
    padding: 0.5rem;
    width: auto;
    max-width: 12rem;
    cursor: pointer;
}
#btn-enviar:hover{
    background-color: var(--principal-hover);
}