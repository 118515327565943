#nombre_universidad{
    font-size: 32px;
    color: var(--principal);
}

#art_somos{
    width: 48%;
    align-items: flex-start;
}
#img_somos{
    width: 48%;
}
#map{
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--principal);
    color: white;
    padding: 1rem;
}
#map h2{
    color: white;
}
iframe{
    border: 0;   
    width: 100%;
    height: 450px;
}
#address{
    font-weight: bold;
}
#calendar{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
#phrase{
    background-color: var(--principal);
    width: 100vw;
    max-width: 100vw;
}
#phrase h2{
    color: white;
    width: 100%;
    text-align: center;
}
#somos {
    align-items: center;
}
@media (max-width: 1199.98px) {
    #art_somos{
        width: 100%;
    }
    #img_somos{
        width: 100%;
    }
    #address{
        text-align: center;
    }
}