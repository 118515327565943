.aside{
    width: 15%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding: 1rem;
    box-sizing: border-box;
    background-color: var(--principal);
    margin-bottom: 1rem;
    position:fixed;
    height: 60vh;
    top: calc(50% - 20vh);
    left: 0;
}
.aside a{
    color: white;
    text-decoration: none;
}
.aside a:hover{
    text-shadow: 0px 0px 9px white;
}
.section-admin{
    width: 70%;
    flex-direction: column;
    align-items: center;
}
.row{
    flex-direction: column;
    align-items: center;
}
#search-aside{
    width: 30%;
    align-items: center;
}
.results{
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 1rem;
    box-sizing: border-box;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}
.result{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.3rem;
    box-shadow: 0px 0px 13px -3px rgba(0,0,0,0.5);
    margin-bottom: 1rem;
}
.res{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.buttons{
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
}
.btn-admin{
    width: 30px;
    height: 30px;
    padding: 0.3rem;
    margin-right: 0.3rem;
    display: flex;
    align-items: center;
}
.btn-admin img{
    filter: invert(1);
}
.btn-admin:hover{
    cursor: pointer;
}
.btn-admin.editar{
    background-color: var(--principal);
}
.btn-admin.editar:hover{
    background-color: var(--principal-hover);
}
.btn-admin.eliminar{
    background-color: var(--red);
}
.btn-admin.eliminar:hover{
    background-color: var(--red-hover);
}
.btn-admin.seleccionar{
    background-color: var(--green);
}
.btn-admin.seleccionar:hover{
    background-color: var(--green-hover);
}
.content{
    margin-top: 30px;
}
.result{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.3rem;
    box-shadow: 0px 0px 13px -3px rgba(0,0,0,0.5);
    margin-bottom: 1rem;
    background-color: white;
}
.post-image{
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.post-image img{
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
}
.result p{
    text-align: center;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.post-title, .user-name{
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    margin-bottom: 0.2rem;
}
@media(max-width: 1199.99px){
    .section-admin{
        width: 100%;
    }
    .results{
        width: 100%;
    }
    #create-publication{
        width: 100%;
    }
    #manage-testimonials{
        width: 100%;
    }
}