#aviso-privacidad{
    display: flex;
    flex-direction: column;
    align-items: center;
}
#aviso-privacidad p{
    width: 100%;
    margin-bottom: 1rem;
}
#aviso-privacidad .centered{
    text-align: center;
    font-weight: bold;
}
#aviso-privacidad .blue{
    color: var(--principal);
    font-size: 20px;
}