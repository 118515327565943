.form-career{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin-top: 1rem;
    box-shadow: 0px 0px 13px -3px rgba(0,0,0,0.5);
    background-color: white;
    margin-bottom: 1rem;
}
.form-group{
    margin-bottom: 1rem;
    width: 100%;
}
@media(max-width: 1199.98px){
    .form-career{
        width: 100%;
    }
}