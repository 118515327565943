#slider{
    max-height: 600px;
    max-width: 100vw;
}
.swiper-wrapper{
    display: flex;
    align-items: center;
}
.swiper-slide{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.img_sld{
    transition: all ease-in-out 300ms;
    cursor:pointer;
}
.img_sld:hover{
    transform: scale(1.05);
}
@media (max-width: 1199.98px){

    #slider{
        max-width: 99vw;
    }
    .swiper{
        max-width: 100%;
    }
}