*{
    box-sizing: border-box;
}
form > img{
    filter:none !important;
    max-width: 500px;
}
form > h1, h4{
    color: black;
    text-align: center;
    margin-top: 0.3rem;
}
form h1{
    font-size: 35px;
}
#login{
    flex-direction: column;
    align-items: center;
}
label{
    width: 100%;
    font-weight: 600;

    margin-top: 0.3rem;
    
}
form input[type="text"], input[type="password"], input[type="email"], input[type="date"]{
    width:100%;
    border: 1px solid black;
    height: 1.7rem;
}
form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
}
.button{
    background-color: var(--principal);
    color: white;
    padding: 0.5rem;
    width: auto;
    cursor: pointer;
}
.button:hover{
    background-color: var(--principal-hover);
}
.input-div{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.7rem;
}
.input-div input{
    text-align: left !important;
}
.input-div img{
    padding: 0.3rem;
    width: 1.65rem;
    height: auto;
    border: 1px solid black;
    border-right: 0px;
}
#btn-mostrar, .btn-mostrar{
    width:auto;
    height: 1.7rem;
    border: none;
    border: 1px solid black;
    border-left: 0px;
    padding: 0;
    cursor: pointer;
    margin: 0;
}
#btn-mostrar img{
    border:none;
}
#password, .password{
    border-right: none;
}
.error{
    color: red;
    font-size: 0.8rem;
    margin-top: 0.2rem;
    background-color: #fcc8cc;
    border: 1px solid red;
    padding: 0.3rem;
    width: 100%;
    margin-bottom: 0.7rem;
}
@media (max-width: 1199.98px) {
    #login {
        max-width: 90vw;
    }
}